import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted,onActivated, getCurrentInstance,nextTick} from 'vue';
import ManualUtil ,{IManualDataObj} from './manualUtil';
import {useRoute} from "vue-router";
import ViewFileUtil from "@/views/project/tools/manual/client/utils/viewFileUtil";
export default defineComponent ({
    name: 'clientManual',
    beforeRouteEnter(to:any,from:any,next:any){
        next((curProxyInst:any)=>{
            document.title = '车辆使用手册';
        })
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let route = useRoute();
        let dataObj:IManualDataObj=reactive<IManualDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            otherParams:{
                cxId:'',
                brandId:'',
                active:[0,1],
                fileData:[]
            }
        })
        onActivated(()=>{
            buildRoutParams();
            nextTick(async()=>{//不能放到onMounted，否则只会请求一次，返回在进入就不会再请求了
                await dataObj.utilInst.initPage();
            })
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ManualUtil(proxy,dataObj);
            buildRoutParams();
        })
        //获取路由的参数
        const buildRoutParams=()=>{
            dataObj.otherParams.cxId=route.query.cxId as string;
            dataObj.otherParams.brandId=route.query.brandId as string;
        }
        onMounted(()=>{

        })
        //打开手册
        const viewFile=async (fileUrl:string,fileName:string)=>{
            await proxy.$router.replace({path:'/clientViewFile',query:{
                fileUrl:fileUrl,fileName:fileName,moduleType:1,
                    cxId:dataObj.otherParams.cxId,//传到文件预览页面去吧，因为返回的时候，在这个页面需要用到这两个参数
                    brandId:dataObj.otherParams.brandId,
            }});
        }
        //返回
        const backHandler=()=>{
            proxy.$router.push({path:'/clientCx',query:{brandId:dataObj.otherParams.brandId}});
        }
        return{
            ...toRefs(dataObj),viewFile,backHandler
        }
    }
});