import BaseBean from "@/utils/BaseBean";

export interface IManualDataObj {
    utilInst:ManualUtil
    refMap:Map<string,any>
    pageListRef:any
    otherParams:any
}

export default class ManualUtil extends BaseBean{
    public dataObj:IManualDataObj

    constructor(proxy:any,dataObj:IManualDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    public async initPage():Promise<any>{
        this.dataObj.otherParams.fileData=await this.utils.ToolsProviderApi.queryManualByCxId({cxId:this.dataObj.otherParams.cxId});
        this.dataObj.otherParams.fileData=this.dataObj.otherParams.fileData.filter((item:any)=>item.uploadFileBeans.length>0)
        // console.log(this.dataObj.otherParams.fileData)
    }

}